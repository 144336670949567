import React from 'react'
import restaurant from '../../images/blog_bmg.jpeg'
import * as styles from './BlogHeader.module.scss'

const BlogHeader = ({ title, mainBlog }) => (
  <div className={styles.header}>
    <div className={styles.headerImgCard}>
      <img
        className={styles.headerImg}
        src={restaurant}
        alt="header blog image"
      />
    </div>
    <div>
      <h1 className={mainBlog ? styles.mainBlogHeader : styles.titleHeader}>
        {title}
      </h1>
    </div>
  </div>
)

export default BlogHeader
