import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import BlogHeader from "../../components/Blog/BlogHeader"
import BlogPost from "../../components/BlogPost/BlogPost"
import Footer from "../../components/Footer/Footer"
import Layout from "../../components/Layout/Layout"
import NavbarContainer from "../../components/misc/NavbarContainer/NavbarContainer"
import useBlogList from "../../hooks/useBlogList"

const BlogPage = () => {
  const blogList = useBlogList()
  const data = useStaticQuery(graphql`{
  banhMi: file(relativePath: {eq: "blog2_banhmi.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  hanoi: file(relativePath: {eq: "blog2_hanoi.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  riceNshine: file(relativePath: {eq: "blog2_riceNshine.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
  shrimp: file(relativePath: {eq: "blog2_shrimp.jpeg"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 80, layout: CONSTRAINED)
    }
  }
}
`)
  const { _, ...photos } = data
  const { title, time, article, postId } = blogList.find(
    blog => blog.postId === "delightful"
  )
  return (
    <ParallaxProvider>
      <Layout title="Blog | Be My Guest Vietnamilainen Ravintola | Jätkäsaari Helsinki">
        <NavbarContainer dark />
        <BlogHeader title={title} />
        <BlogPost
          time={time}
          article={article}
          photos={photos}
          postId={postId}
        />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default BlogPage
