import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import React from 'react'
import useBlogList from '../../hooks/useBlogList'
import useBlogPhotos from '../../hooks/useBlogPhotos'
import * as styles from './BlogSideBar.module.scss'

const BlogSideBar = ({ postId }) => {
  const photos = useBlogPhotos()
  const blogList = useBlogList()
  return (
    <>
      <h1 className={styles.title}>Read more</h1>
      {blogList
        .filter((b) => b.postId !== postId)
        .map((blog, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.blog1Img}>
              <GatsbyImage
                className={styles.img}
                image={photos[blog.photoId].childImageSharp.gatsbyImageData}
                alt="error"
              />
            </div>
            <Link to={blog.link}>
              <p className={styles.text}>{blog.title}</p>
            </Link>
          </div>
        ))}
    </>
  )
}

export default BlogSideBar
