import { useStaticQuery, graphql } from 'gatsby'

export const useBlogList = () => {
  const data = useStaticQuery(graphql`
    query {
      blogList: allBlogPostsYaml {
        nodes {
          postId
          time
          title
          photoId
          link
          id
          description
          altPhoto
          article {
            type
            content
            photoId
            photoAlt
          }
        }
      }
    }
  `)
  return data.blogList.nodes
}

export default useBlogList
